import './chat.sass'

import React, { useState, useEffect, useRef } from 'react'
import { Provider as ReduxProvider, useDispatch, useSelector } from 'react-redux'
import { closeChat, closeChatList, setMarkedSeen } from './redux/reducers/chatSlice'

import PropTypes from 'prop-types'

import {
  useCreateMessageMutation,
  useGetRoomChatMessagesQuery,
  useMarkMessagesReadMutation,
} from './redux/reducers/apiSlice'
import reduxStore from './redux/store'
import Grid from '@material-ui/core/Grid'
import Avatar from '@material-ui/core/Avatar'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import ChatIcon from '@material-ui/icons/Chat'
import Button from '@material-ui/core/Button'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import moment from 'moment'
import { AtheneumIcon } from './'
import { find, pick, includes, first, isEmpty } from 'lodash'

export const Chat = ({
  user,
  className,
  staticData,
}: {
  user: {
    id: number
    fullName: string
    userTypeId: number
    expert: {
      id: number
    }
    client: {
      id: number
    }
    employee: {
      id: number
    }
    currentPosition: {
      employeePositionId: number
    }
    picture: {
      path: string
    }
    permissions: any
  }
  className?: string
  staticData?: any
}) => {
  return (
    <ReduxProvider store={reduxStore}>
      <ChatWindow
        user={pick(user, [
          'id',
          'fullName',
          'picture',
          'userTypeId',
          'permissions',
          'expert',
          'client',
          'employee',
          'currentPosition',
        ])}
        className={className}
        staticData={staticData}
      />
    </ReduxProvider>
  )
}

const ChatWindow = ({
  user,
  className,
  staticData,
}: {
  user: {
    id: number
    fullName: string
    userTypeId: number
    expert: {
      id: number
    }
    client: {
      id: number
    }
    employee: {
      id: number
    }
    currentPosition: {
      employeePositionId: number
    }
    picture: {
      path: string
    }
    permissions: any
  }
  className?: string
  staticData?: any
}) => {
  const [message, setMessage] = useState('')
  const markedSeen = useSelector((state: any) => state.chat.markedSeen)
  const activeRoom = useSelector((state: any) => state.chat.activeRoom)
  const eplChat = useSelector((state: any) => state.chat.eplChat)
  const chatListOpen = useSelector((state: any) => state.chat.chatListOpen)
  const activeChatClass = useSelector((state: any) => state.chat.activeChatClass)
  const dispatch = useDispatch()

  let meta = {} as {
    projectId: number | null
    segmentId: number | null
    expertId: number | null
    eplId: number | null
    user: any
    clientOfficeId: number | undefined
    context: string
  }

  const isFromList = activeRoom && Array.isArray(activeRoom.meta)

  if (isFromList) {
    activeRoom.meta.forEach(({ key, value }: { key: string; value: string }) => Object.assign(meta, { [key]: value }))
  } else {
    meta = activeRoom?.meta || meta
  }
  const chatUser = pick(user, ['id', 'fullName', 'picture', 'userTypeId'])
  const roomData = {
    user: chatUser,
    meta,
    roomName: activeRoom?.name || '',
  } as any

  useGetRoomChatMessagesQuery({ meta })
  const [markMessagesRead] = useMarkMessagesReadMutation()

  if (activeRoom && !markedSeen) {
    markMessagesRead(roomData)
    dispatch(setMarkedSeen(true))
  }

  const messages = useSelector((state: any) => state.chat.messages)
  const [createMessage] = useCreateMessageMutation()
  const messagesEndRef = useRef(null) as any

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages, messagesEndRef])

  const currentUserId = user.id

  const saveMessage = async (e: any) => {
    if (!message) return
    const newMessage = {
      user: chatUser,
      message,
      roomName: roomData.roomName,
      meta: roomData.meta,
    }
    setMessage('')
    await createMessage(newMessage).unwrap()
  }

  const hasPermission = checkPermission(user, staticData, roomData.meta.context)
  const isSendButtonDisabled =
    !hasPermission || (!isEmpty(messages) && messages.length === 1 && (first(messages) as any)?.user?.type === 1)

  const clientReplyText = user.client || user.expert ? 'We are going to reply as soon as possible.' : ''
  const welcomeMessage =
    'Welcome back ' +
    chatUser.fullName +
    '. Happy to see you again. Please write your message here. Your Atheneum contact is going to reply as soon as possible.'
  if (!activeRoom || activeChatClass !== className) return <div></div>

  return (
    <div className={`chat-wrapper ${className || 'fixed'}`}>
      <div className="chat-header">
        <div className="left">
          <ChatIcon style={{ fontSize: 32, color: '#fff' }} />
          <div className="chat-header-text">
            <div>{activeRoom.name}</div>
            <div className="reply-text">{clientReplyText}</div>
          </div>
        </div>
        <CloseIcon style={{ color: '#fff' }} onClick={() => dispatch(closeChatList())} />
      </div>
      {(chatListOpen || eplChat) && (
        <div
          className="back"
          onClick={() => {
            dispatch(closeChat())
          }}
        >
          <ArrowBackIcon />
          Back to all messages
        </div>
      )}
      <div className="chat">
        {user.userTypeId !== 1 && (
          <ChatMsg
            key="key"
            avatar={<AtheneumIcon className="welcome-avatar" />}
            showAvatar={true}
            message={welcomeMessage}
          />
        )}
        {messages &&
          messages.length > 0 &&
          messages.map((message: any, index: number) => {
            let showAvatar = index === 0 || false
            const prevMessageDate = index > 0 ? moment(messages[index - 1].created_at).format('MMM D, YYYY') : null
            const currentMessageDate = moment(message.created_at).format('MMM D, YYYY')
            const date = index === 0 || prevMessageDate !== currentMessageDate ? currentMessageDate : ''

            const prevMessageTime = index > 0 ? moment(messages[index - 1].created_at).format('HH:mm') : null
            const currentMessageTime = moment(message.created_at).format('HH:mm')
            const time = prevMessageTime !== currentMessageTime ? currentMessageTime : null

            if (index > 0 && (message.userId !== messages[index - 1].userId || time)) {
              showAvatar = true
            }
            let avatar = message.user.type === 1 ? <AtheneumIcon /> : ''
            if (!avatar && message.user.avatar) {
              avatar = '/uploads/' + message.user.avatar
            }

            let userName = message.user.type === 1 && user.userTypeId !== 1 ? 'Atheneum' : message.user.name
            userName = message.user.id === user.id ? '' : userName

            return (
              <ChatMsg
                key={index}
                side={message.user.id === currentUserId && 'right'}
                avatar={avatar}
                showAvatar={showAvatar}
                time={time}
                date={date}
                userName={userName}
                message={message.message}
              />
            )
          })}
        <div ref={messagesEndRef} />
      </div>
      {hasPermission && (
        <div className="chat-footer">
          <textarea
            onChange={(e) => setMessage(e.target.value)}
            value={message}
            rows={4}
            placeholder="Write your message..."
          ></textarea>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={saveMessage}
            disabled={isSendButtonDisabled}
          >
            Send
          </Button>
        </div>
      )}
      {!hasPermission && <div className="missing-permission">You only have have permission to read messages.</div>}
    </div>
  )
}

const checkPermission = (user: any, staticData: any, context: string) => {
  if (user.client || context === 'expert') {
    return true
  }

  if (user.employee && staticData) {
    const { ACCOUNT_MANAGER, TEAM_LEADER, PRINCIPAL } = staticData.CONST.EMPLOYEE_POSITIONS
    const { CLIENT_MESSAGING } = staticData.CONST.PERMISSIONS

    const hasPermission = !!find(user.permissions, { id: CLIENT_MESSAGING })

    return includes([ACCOUNT_MANAGER, TEAM_LEADER, PRINCIPAL], user.currentPosition.employeePositionId) || hasPermission
  }
  return false
}

const ChatMsg = (props: any) => {
  const { avatar, showAvatar, time, date, userName, message, side } = props
  const isRight = side === 'right'
  return (
    <div>
      <div className="date">{date}</div>
      <Grid
        container
        spacing={2}
        className={`${isRight ? 'right' : 'left'} ${showAvatar ? 'new' : ''}`}
        alignItems="flex-end"
        wrap="nowrap"
      >
        {showAvatar && (
          <Grid item>
            <Avatar src={typeof avatar === 'string' ? avatar : ''} className="picture">
              {avatar || <AccountCircleIcon className="default-profile" />}
            </Avatar>
          </Grid>
        )}
        <Grid item>
          {time && (
            <div className="message-data">
              <div className="username">{userName}</div>
              <div className="time">{time}</div>
            </div>
          )}
          <Typography align={'left'} className="message">
            {message}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

ChatMsg.propTypes = {
  showAvatar: PropTypes.bool,
  avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  time: PropTypes.string,
  date: PropTypes.string,
  userName: PropTypes.string,
  message: PropTypes.string,
  side: PropTypes.oneOf([false, 'right']),
}

ChatMsg.defaultProps = {
  avatar: '',
  message: '',
  side: false,
}
