import './chat.sass'

import React from 'react'
import { Provider as ReduxProvider, useDispatch } from 'react-redux'
import { useGetUserUnseenCountQuery } from './redux/reducers/apiSlice'
import { openEplChat } from './redux/reducers/chatSlice'
import reduxStore from './redux/store'
import ForumIcon from '@material-ui/icons/Forum'
import pick from 'lodash/pick'

interface EPLChatRoomProps {
  user: any
  eplId: number
  room?: {
    name: string
    meta: {
      context: 'expert'
      eplId: number
      expertId: number
      segmentId: number
      projectId: number
    }
  }
}

export const EPLChatRoom = ({ user, eplId, ...rest }: EPLChatRoomProps) => {
  return (
    <ReduxProvider store={reduxStore}>
      <EPLChatRoomContainer user={pick(user, ['id', 'fullName', 'picture', 'userTypeId'])} eplId={eplId} {...rest} />
    </ReduxProvider>
  )
}
const EPLChatRoomContainer = ({ user, eplId, room }: EPLChatRoomProps) => {
  const { data, isSuccess }: any = useGetUserUnseenCountQuery({
    userId: user.id,
    meta: { eplId },
  })
  const dispatch = useDispatch()
  return (
    <div>
      {isSuccess && (
        <div className="epl-chat-wrapper">
          <ForumIcon
            color="inherit"
            style={{ fontSize: 22, color: '#3A86BF' }}
            onClick={(e) => {
              e.stopPropagation()
              dispatch(openEplChat({ eplId, room }))
            }}
          />
          {!!data.unseenCount && <div className="chat-unseen">{data.unseenCount}</div>}
        </div>
      )}
    </div>
  )
}
